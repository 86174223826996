exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedrooms-tsx": () => import("./../../../src/pages/bedrooms.tsx" /* webpackChunkName: "component---src-pages-bedrooms-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-homeentertainment-tsx": () => import("./../../../src/pages/homeentertainment.tsx" /* webpackChunkName: "component---src-pages-homeentertainment-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kitchens-tsx": () => import("./../../../src/pages/kitchens.tsx" /* webpackChunkName: "component---src-pages-kitchens-tsx" */),
  "component---src-pages-studies-tsx": () => import("./../../../src/pages/studies.tsx" /* webpackChunkName: "component---src-pages-studies-tsx" */)
}

